import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from '../PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import format from 'date-fns/format'
import { Button, Col, Row } from 'react-bootstrap'
import { setCurrentActionId } from '../../features/user/userAction'

const Enrollment = ({ action }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)

  const handleStart = () => {
    dispatch(setCurrentActionId(action.sessionActionId))
    navigate(`/program/${action.program.programId}`)
  }

  const handlePatientStart = () => {
    dispatch(setCurrentActionId(action.sessionActionId))
    navigate(`/patient-program/${action.program.programId}`)
  }

  const practiceSigned = action.logs.find((l) => l.status === 'PRACTICE_SIGNED')
  const sentToPool = action.logs.find((l) => l.status === 'SENT_TO_POOL')
  const patientSigned = action.logs.find((l) => l.status === 'PATIENT_CERTIFICATION_FINISHED')

  if (user === 'PATIENT') {
    return (
      <>
        <div className='d-flex justify-content-between'>
          <h2 className='h4 font-weight-bold'>{action.program.medicationName}{action.program.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {action.program?.name}</h2>
          {
            !patientSigned && (
              <PrimaryButton
                color='#3B6E35'
                secondcolor='#5FAD56'
                size='sm'
                onClick={handlePatientStart}
              >
                Start Patient Consent
              </PrimaryButton>
            )
          }
        </div>
        {
          patientSigned ? <p className='text-success font-weight-bold'>Patient online consent was completed on {format(new Date(patientSigned.createdAt), 'MM/dd/yyyy')}. No further action is needed.</p> : null
        }
        {patientSigned && (
          <>
            <p>You will hear from the {action.program.medicationName} {action.program.name} Program in the next few days with an update on progress.</p>
            <Row>
              <Col>
                <dl className="program_contact">
                  <dt>Program Contact:</dt>
                  <dd> {action.program.contactName}</dd>

                  <dt>Program Phone Number:</dt>
                  <dd> {action.program.contactPhone}</dd>

                  <dt>Program Email:</dt>
                  <dd> {action.program.contactEmail}</dd>
                </dl>
              </Col>
            </Row>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <h2 className='h4 font-weight-bold'>{action.program.medicationName}{action.program.includeRegisteredTrademark === 1 && (<sup>&reg;</sup>)} {action.program?.name}</h2>
        {
          !practiceSigned && !sentToPool && (
            <PrimaryButton
              color='#3B6E35'
              secondcolor='#5FAD56'
              size='sm'
              onClick={handleStart}
            >
              Start Provider Enrollment Process
            </PrimaryButton>
          )
        }
      </div>
      {
        practiceSigned && (
          <div className='d-flex justify-content-between'>
            <p className='text-success font-weight-bold'>The provider&apos;s portion of the enrollment was completed on {format(new Date(practiceSigned.createdAt), 'MM/dd/yyyy')}</p>
          </div>
        )
      }
      {
        sentToPool && !practiceSigned && (
          <div className='d-flex justify-content-between'>
            <p className='text-danger font-weight-bold'>Enrollment was started on {format(new Date(sentToPool.createdAt), 'MM/dd/yyyy')} and sent to Pool for completion</p>
            {
              user === 'POOL' && (
                <PrimaryButton
                  color='#3B6E35'
                  secondcolor='#5FAD56'
                  size='sm'
                  onClick={handleStart}
                >
                  Complete Enrollment
                </PrimaryButton>
              )
            }
          </div>
        )
      }
      {
        !patientSigned && (practiceSigned || sentToPool) ? (
          <>
            <Row className='mt-2'>
              <Col xs={8}>
                <p className='text-danger'>A MyChart announcement has been sent to the patient and they should complete their consent online. The enrollment will not be submitted until patient consent is received.</p>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={9}>
                <p>If the patient does not use MyChart, or if they would prefer to complete a paper form, print one now</p>
              </Col>
              <Col xs={3} className='text-right'>
                <Button
                  size='sm'
                  variant='outline-secondary'
                >
                  Print Paper Consent Form
                </Button>
              </Col>
            </Row>
          </>
        ) : null
      }
      {
        patientSigned && (practiceSigned || sentToPool) ? <p className='text-success font-weight-bold'>Patient online consent was completed on {format(new Date(patientSigned.createdAt), 'MM/dd/yyyy')}. No further action is needed.</p> : null
      }
    </>
  )
}

Enrollment.propTypes = {
  action: PropTypes.any
}

export default Enrollment