import types from '../types'

const initialState = {
  getForm: {
    loading: false,
    success: false,
    error: false
  },
  form: {},
  formValues: {},
  initializedForm: false,
  enroll: {
    loading: false,
    success: false,
    error: false,
    data: {}
  },
  sendToPool: {
    loading: false,
    success: false,
    error: false,
    data: {}
  },
  printPdf: {
    loading: false,
    success: false,
    error: false
  },
  sendPatientCopayOffer: {
    loading: false,
    success: false,
    error: false,
  },
  pdfName: ''
}

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PROVIDER_FORM_REQUESTED: {
    return {
      ...state,
      getForm: {
        loading: true,
        success: false,
        error: false
      }
    }
  }
  case types.GET_PROVIDER_FORM_FULFILLED: {
    return {
      ...state,
      getForm: {
        loading: true,
        success: true,
        error: false
      },
      form: action.payload
    }
  }
  case types.GET_PROVIDER_FORM_REJECTED: {
    return {
      ...state,
      getForm: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.SIGN_ENROLLMENT_REQUESTED: {
    return {
      ...state,
      enroll: {
        loading: true,
        success: false,
        error: false,
        data: {}
      }
    }
  }
  case types.SIGN_ENROLLMENT_FULFILLED: {
    return {
      ...state,
      enroll: {
        loading: false,
        success: true,
        error: false,
        data: action.payload
      }
    }
  }
  case types.SIGN_ENROLLMENT_REJECTED: {
    return {
      ...state,
      enroll: {
        loading: false,
        success: false,
        error: true,
        data: {}
      }
    }
  }
  case types.SEND_TO_POOL_REQUESTED: {
    return {
      ...state,
      sendToPool: {
        loading: true,
        error: false,
        success: false,
        data: {}
      }
    }
  }
  case types.SEND_TO_POOL_FULFILLED: {
    return {
      ...state,
      sendToPool: {
        loading: false,
        success: true,
        error: false,
        data: action.payload
      }
    }
  }
  case types.SEND_TO_POOL_REJECTED: {
    return {
      ...state,
      sendToPool: {
        loading: false,
        success: false,
        error: true,
        data: {}
      }
    }
  }
  case types.PRINT_PDF_REQUESTED: {
    return {
      ...state,
      printPdf: {
        loading: true,
        success: false,
        error: false
      },
      pdfName: ''
    }
  }
  case types.PRINT_PDF_FULFILLED: {
    return {
      ...state,
      printPdf: {
        loading: false,
        success: true,
        error: false
      },
      pdfName: action.payload
    }
  }
  case types.PRINT_PDF_REJECTED: {
    return {
      ...state,
      printPdf: {
        loading: false,
        success: false,
        error: true
      }
    }
  }
  case types.INIT_FORM: {
    const { form, conditions, program, sessionAction } = action.payload
    const { columns } = form

    const formSections = [].concat(...columns.map((c) => c))
    const formElements = [].concat(...formSections.map((s) => s.elements)).filter((element) => element.elementType.isFormElement === 1)

    let formValues = {}
    const formSubmission = sessionAction.formSubmission
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i]
      const name = element.name ? element.name : element.element.name

      let value = ''
      if (formSubmission) {
        const submissionValue = formSubmission.data.find((d) => d.formElementId === element.formElementId)
        value = submissionValue ? submissionValue.value : ''
      }
      formValues[name] = {
        elementId: element.formElementId,
        value
      }

      if (element.element) {
        if (element.element.options.length > 0) {
          for (let k = 0; k < element.element.options.length; k += 1) {
            const option = element.element.options[k]
            if (option.option.value.toLowerCase().includes('other')) {
              formValues[`${name}Other`] = {
                elementId: element.formElementId,
                value
              }
            }
          }
        }
      }
    }

    // PRIMARY DIAGNOSIS
    if (!formSubmission) {
      const programDiagnosisCodes = program.codes.reduce((acc, curr) => {
        if (curr.code.type === 'diagnosis') {
          return [...acc, curr.code.code]
        }
        return acc
      }, [])
      const foundCode = conditions.find((condition) => programDiagnosisCodes.includes(condition.code))
      formValues.primaryDiagnosis.value = foundCode ? foundCode.code : ''
    }
    

    return {
      ...state,
      getForm: {
        ...state.getForm,
        loading: false
      },
      initializedForm: true,
      formValues
    }
  }
  case types.UPDATE_FORM_VALUE: {
    const { name, value } = action.payload

    const formValues = state.formValues
    formValues[name].value = value

    return {
      ...state,
      formValues
    }
  }
  case types.SEND_PATIENT_COPAY_OFFER_REQUESTED: {
    return {
      ...state,
      sendPatientCopayOffer: {
        loading: true,
        error: false,
        success: false
      }
    }
  }
  case types.SEND_PATIENT_COPAY_OFFER_FULFILLED: {
    return {
      ...state,
      sendPatientCopayOffer: {
        loading: false,
        error: false,
        success: true
      }
    }
  }
  case types.SEND_PATIENT_COPAY_OFFER_REJECTED: {
    return {
      ...state,
      sendPatientCopayOffer: {
        loading: false,
        error: true,
        success: false
      }
    }
  }
  case types.RESET_PROVIDER_STATE: {
    return {
      ...state,
      enroll: {
        loading: false,
        success: false,
        error: false,
        data: {}
      },
      sendToPool: {
        loading: false,
        success: false,
        error: false,
        data: {}
      },
    }
  }
  default:
    return state
  }
}

export default providerReducer